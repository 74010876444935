import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css'

import {
    setCookie,
    getCookie,
    delCookie
} from "./static/js/cookie";
import {
    configure
} from "./static/js/config";
import ajax from "./api/index";

// 全局混入
let Mixin = require('./static/js/mixins');
Vue.mixin(Mixin);


//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    store.commit('upTitle', to.meta.title)
    if (localStorage.getItem('adminInfo')) {
        store.commit('upAdminInfo', JSON.parse(localStorage.getItem('adminInfo')))
    }
    let token = null

    if (configure.tokenType == 1) {
        token = localStorage.getItem('token')
    } else if (configure.tokenType == 2) {
        token = sessionStorage.getItem('token')
    } else {
        token = getCookie('token')
    }

    // if (to.path !== '/Login' && !token) {
    //     return next({
    //         path: '/Login'
    //     })
    // } else {
    //     next()
    // }

    if (to.path !== '/Login' && !token) {
        return next({
            path: '/Login'
        })
    }
    if (to.path == '/Login') {
        return next()
    }

    if (to.path == '/') {
        return next({
            path: '/Home'
        })
    }

    next()


})


Vue.config.productionTip = false
Vue.prototype.$setCookie = setCookie;
Vue.prototype.$getCookie = getCookie;
Vue.prototype.$delCookie = delCookie;
Vue.prototype.$ajax = ajax
Vue.use(ElementUI);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
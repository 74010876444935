module.exports = {
    data() {
        return {
            Img: 'https://img1.baidu.com/it/u=592570905,1313515675&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1656090000&t=57db7bca9b84ff3c628ad697b33e7193'
        }
    },
    methods: {
        // 默认图片
        defaultImg() {
            return "https://img1.baidu.com/it/u=592570905,1313515675&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1656090000&t=57db7bca9b84ff3c628ad697b33e7193";
        },
        // 转为时间搓
        dataChange(d) {
            var d = new Date(d);
            let time =
                d.getFullYear() +
                "-" +
                this.twoDate(d.getMonth() + 1) +
                "-" +
                this.twoDate(d.getDate()) +
                " " +
                this.twoDate(d.getHours()) +
                ":" +
                this.twoDate(d.getMinutes()) +
                ":" +
                this.twoDate(d.getSeconds());
            return time;
        },

        // 时间戳转为日期
        formatDate(timestamp) {
            let date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
            let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
            let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },

        // Date格式转日期
        formatDate1(date) {
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? '0' + m : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + m + '-' + d;
        },

        // 补0
        twoDate(d) {
            return d < 10 ? "0" + d : d;
        },

        // 清单导出
        invenExportChange(arr) {
            this.$ajax.inventoryExport({ ids: arr.join(',') }).then(res => {
                if (res.status == 200) {
                    window.open(res.url, '_blank')
                }
            })
        },
        // 订单导出
        orderExportChange(arr) {
            this.$ajax.orderExport({ ids: arr.join(',') }).then(res => {
                if (res.status == 200) {
                    window.open(res.url, '_blank')
                }
            })
        }
    }
}
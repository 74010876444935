import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'homeView',
    component: () =>
        import('../views/HomeView/HomeView.vue'),
    children: [{
        path: '/Home',
        name: 'home',
        component: () =>
            import('../views/Home.vue'),
        meta: {
            title: "个人中心",
            keepAlive: false,
        },
    }, {
        path: '/service',
        name: 'service',
        component: () =>
            import('../views/Service/index'),
        meta: {
            title: "客服中心",
            keepAlive: false,
        },
    },
    {
        path: '/404',
        name: '404',
        component: () =>
            import('../views/error/404.vue')
    },
    {
        path: "/EnterOrder",
        name: "enterOrder",
        component: () =>
            import("../views/Offline/EnterOrder.vue"),
        meta: {
            title: "订单录入",
            keepAlive: false,
        },
    },
    {
        path: "/OrderManage",
        name: "orderManage",
        component: () =>
            import("../views/Offline/OrderManage.vue"),
        meta: {
            title: "订单管理",
            keepAlive: false,
        },
    },
    {
        path: "/DraftOrder",
        name: "draftOrder",
        component: () =>
            import("../views/Offline/DraftOrder.vue"),
        meta: {
            title: "订单草稿",
            keepAlive: false,
        },
    },
    {
        path: "/OrderList",
        name: "OrderList",
        component: () =>
            import("../views/Online/OrderList.vue"),
        meta: {
            title: "待接订单",
            keepAlive: false,
        },
    },
    {
        path: "/MyOrder",
        name: "MyOrder",
        component: () =>
            import("../views/Online/MyOrder.vue"),
        meta: {
            title: "已接订单",
            keepAlive: false,
        },
    },
    {
        path: "/OrderInfo",
        name: "OrderInfo",
        component: () =>
            import("../views/OrderInfo/OrderInfo.vue"),
        meta: {
            title: "订单详情",
            keepAlive: false,
        },
    },
    {
        path: '/PersonalData',
        name: 'personalData',
        component: () =>
            import('../views/personalCenter/personalData.vue'),
        meta: {
            title: "个人资料",
            keepAlive: false,
        },
    },
    {
        path: '/ModifyPhone',
        name: 'modifyPhone',
        component: () =>
            import('../views/personalCenter/modifyPhone.vue'),
        meta: {
            title: "修改手机",
            keepAlive: false,
        },
    },
    {
        path: '/PasswordSetting',
        name: 'passwordSetting',
        component: () =>
            import('../views/personalCenter/passwordSetting.vue'),
        meta: {
            title: "密码设置",
            keepAlive: false,
        },
    },
    {
        path: '/AllAppointment',
        name: 'allAppointment',
        component: () =>
            import('../views/Appointment/allAppointment.vue'),
        meta: {
            title: "所有预约",
            keepAlive: false,
        },
    },
    {
        path: '/MyAppointment',
        name: 'myAppointment',
        component: () =>
            import('../views/Appointment/myAppointment.vue'),
        meta: {
            title: "我的预约",
            keepAlive: false,
        },
    },
    {
        path: '/AllOrders',
        name: 'allOrders',
        component: () =>
            import('../views/allOrders/allOrders.vue'),
        meta: {
            title: "所有订单",
            keepAlive: false,
        },
    },
    {
        path: '/InvoicingList',
        name: 'invoicingList',
        component: () =>
            import('../views/invoicingList/invoicingList.vue'),
        meta: {
            title: "开票列表",
            keepAlive: false,
        },
    },
    ]
},
{
    path: '/Login',
    name: 'login',
    component: () =>
        import('../views/Login.vue'),
    meta: {
        title: "登录",
        keepAlive: false,
    },
},
{
    path: '*',
    redirect: '/404',
    hidden: true
}
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
export default router
// //引入ajax文件
import request from "../static/js/ajax";
// 获取首页数据
export default {
	// 获取验证码图片
	getCode(query) {
		return request({
			url: "code",
			method: "get",
			query: query
		});
	},

	// 验证验证码
	verificationCode(query) {
		return request({
			url: "code",
			method: "post",
			data: query
		});
	},

	// 登录
	login(query) {
		return request({
			url: "login",
			method: "post",
			data: query,
		});
	},

	// 订单录入
	enterOrder(query) {
		return request({
			url: "api/service/v1/offline/order_enter",
			method: "post",
			data: query,
		});
	},
	// 取消订单
	cancelOrder(query) {
		return request({
			url: "api/service/v1/order/cancel",
			method: "put",
			data: query,
		});
	},

	// 材料上传
	uploadData(query) {
		return request({
			url: "api/service/v1/order/material",
			method: "put",
			data: query,
		});
	},

	// 获取支付二维码
	getPayCode(query) {
		return request({
			url: "api/service/v1/order/pay",
			method: "post",
			data: query,
		});
	},
	// 获取订单状态
	getStatus(query) {
		return request({
			url: "api/service/v1/order/status",
			method: "get",
			params: query,
		});
	},
	// 上传支付凭证
	uploadCode(query) {
		return request({
			url: "api/service/v1/order/pic",
			method: "post",
			data: query,
		});
	},

	// 搜索出行人
	searchTrip(query) {
		return request({
			url: "api/service/v1/trip/search",
			method: "get",
			params: query,
		});
	},

	// 新增出行人
	addTrip(query) {
		return request({
			url: "api/service/v1/trip/create",
			method: "post",
			data: query,
		});
	},

	// 编辑出行人
	editTrip(query) {
		return request({
			url: "api/service/v1/trip/edit",
			method: "put",
			data: query,
		});
	},

	// 删除出行人
	delTrip(query) {
		return request({
			url: "api/service/v1/order/trip",
			method: "delete",
			data: query,
		});
	},

	// 获取省市区
	getRegion(query) {
		return request({
			url: "api/service/v1/trip/treeDistrict",
			method: "get",
			query: query,
		});
	},

	// 获取首页
	getIndex(query) {
		return request({
			url: "api/service/v1/index/index",
			method: "get",
			params: query,
		});
	},

	// 获取柱状图数据
	getChart(query) {
		return request({
			url: "api/service/v1/index/column",
			method: "get",
			params: query,
		});
	},
	// 新增收件地址
	addAddress(query) {
		return request({
			url: "api/service/v1/address/create",
			method: "post",
			data: query,
		});
	},

	// 获取订单模板
	getModel(query) {
		return request({
			url: "api/service/v1/order/template",
			method: "get",
			params: query,
		});
	},

	// 获取产品列表
	gerProduct(query) {
		return request({
			url: "api/service/v1/product/search",
			method: "get",
			query: query,
		});
	},

	// 获取订单列表
	getOrdelist(query) {
		return request({
			url: "api/service/v1/order/list",
			method: "get",
			params: query
		});
	},

	// 订单详情
	getOrderInfo(query) {
		return request({
			url: "api/service/v1/order/list/" + query.id,
			method: "get",
			data: query,
		});
	},

	// 订单修改价格
	modifyPrice(query) {
		return request({
			url: "api/service/v1/order/price",
			method: "put",
			data: query
		});
	},

	// 删除草稿
	delDraft(query) {
		return request({
			url: "api/service/v1/order/delete",
			method: "delete",
			data: query
		});
	},

	// 订单录入
	submitOrder(query) {
		return request({
			url: "api/service/v1/order/enter",
			method: "post",
			data: query
		});
	},
	// 退款
	orderRefund(query) {
		return request({
			url: "api/service/v1/order/refund",
			method: "post",
			data: query
		});
	},

	// 订单预审通过
	orderAdopt(query) {
		return request({
			url: "api/service/v1/order/examination",
			method: "put",
			data: query
		});
	},

	// 订单确认收件/顺丰取件/填写单号
	orderReceiving(query) {
		return request({
			url: "api/service/v1/order/receiving",
			method: "post",
			data: query
		});
	},

	// 订单受理
	orderAcceptance(query) {
		return request({
			url: "api/service/v1/order/dispose",
			method: "post",
			data: query
		});
	},

	// 提交大使馆
	orderEmbassy(query) {
		return request({
			url: "api/service/v1/order/embassy",
			method: "post",
			data: query
		});
	},

	// 订单上传凭证
	orderVoucher(query) {
		return request({
			url: "api/service/v1/order/voucher",
			method: "post",
			data: query
		});
	},

	// 获取取件地址
	getAddress(query) {
		return request({
			url: "api/service/v1/order/address",
			method: "get",
			params: query,
		});
	},

	// 取件提交
	addTake(query) {
		return request({
			url: "api/service/v1/order/take",
			method: "post",
			data: query,
		});
	},

	//抢单 
	snatchOrders(query) {
		return request({
			url: "api/service/v1/order/snatch",
			method: "put",
			data: query,
		});
	},

	//转单
	transOrders(query) {
		return request({
			url: "api/service/v1/order/trans",
			method: "put",
			data: query,
		});
	},

	//获取站点
	getSite(query) {
		return request({
			url: "api/service/v1/order/account",
			method: "get",
			params: query,
		});
	},

	// 订单完成
	orderComplete(query) {
		return request({
			url: "api/service/v1/order/complete",
			method: "post",
			data: query,
		});
	},

	// 操作日志
	getorderLogs(query) {
		return request({
			url: "api/service/v1/order/logs",
			method: "get",
			params: query,
		});
	},

	// 添加操作日志
	postorderLogs(query) {
		return request({
			url: "api/service/v1/order/logs",
			method: "post",
			data: query,
		});
	},

	// 个人资料详情
	getPersonalInfo(query) {
		return request({
			url: 'api/finance/v1/user/info',
			method: "get",
			query: query,
		});
	},

	//修改个人资料
	modifyPersonal(query) {
		return request({
			url: "api/finance/v1/user/modify",
			method: "put",
			data: query,
		});
	},

	// 发送短信验证码
	sendSms(query) {
		return request({
			url: 'api/finance/v1/user/sms',
			method: "get",
			query: query,
		});
	},

	//修改手机号
	modifyMobile(query) {
		return request({
			url: "api/finance/v1/user/mobile",
			method: "put",
			data: query,
		});
	},

	//修改密码
	modifyPassword(query) {
		return request({
			url: "api/finance/v1/user/password",
			method: "put",
			data: query,
		});
	},

	// 客服列表
	getCustomerServiceList(query) {
		return request({
			url: 'api/service/v1/user/customer',
			method: "get",
			query: query,
		});
	},

	// 临时转单
	temporaryTransferOrder(query) {
		return request({
			url: "api/service/v1/order/temporary",
			method: "put",
			data: query,
		});
	},
	// 所有订单
	getAllOrderList(query) {
		return request({
			url: 'api/service/v1/order/listall',
			method: "get",
			query: query,
		});
	},

	// 开票列表
    getInvoicingList(query) {
		return request({
			url: "api/service/v1/order/invoic",
			method: "get",
	        query: query,
		});
	},
	// 订单导出
	orderExport(query) {
		return request({
			url: "api/service/v1/order/export_order",
			method: "get",
	        query: query,
		});
	},
	// 开票导出
	invoicExport(query) {
		return request({
			url: "api/service/v1/order/export_invoic",
			method: "get",
	        query: query,
		});
	},
	// 清单导出
	inventoryExport(query) {
		return request({
			url: "api/service/v1/order/export_inventory",
			method: "get",
	        query: query,
		});
	},
	// 所有预约
	getAllAppointment(query){
		return request({
			url: "api/service/v1/yuyue/listall",
			method: "get",
	        query: query,
		});
	},
	// 我的预约
	getMyAppointment(query){
		return request({
			url: "api/service/v1/yuyue/mylist",
			method: "get",
	        query: query,
		});
	},
	// 确认接单
	submitReceiveOrder(query){
		return request({
			url: "api/service/v1/yuyue/receive",
			method: "post",
	        data: query,
		});
	},
	// 确认订单
	appointmentComplete(query){
		return request({
			url: "api/service/v1/yuyue/complete",
			method: "post",
	        data: query,
		});
	},
	getKey(query){
		return request({
			url: "api/service/v1/access",
			method: "get",
	        data: query,
		});
	},
};
